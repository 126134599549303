import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>
        Built by <span>WOO-SIK CHOI</span> and updated on{' '}
        <span>SEPTEMBER 2022</span>
      </p>
    </div>
  );
}

export default Footer;
